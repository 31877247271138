<div *ngIf="records.length > 0">

    <p-table responsiveLayout="scroll" [value]="records" [rows]="10" [paginator]="true" styleClass="p-datatable-gridlines" >

        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Identificación
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Detalle
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-record>
            <tr>
                <td>
                    {{record.id}}
                </td>
                <td>
                    {{record.name}}
                </td>
                <td>
                    {{record.detail}}
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
