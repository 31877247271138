import {Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {RippleModule} from "primeng/ripple";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ReactiveFormsModule} from "@angular/forms";
import {DialogModule} from "primeng/dialog";
import {DatePipe, JsonPipe, NgIf} from "@angular/common";
import {ReportLinkDirective} from "../../directives/report-link.directive";
import {Observable} from "rxjs";
import {SearchCountResponse} from "../../interfaces/search-count";
import {Store} from "@ngxs/store";
import {SearchCountState} from "../../store/user/search-count/search-count.state";
import {PaginatorModule} from "primeng/paginator";
import {HistoricSearchService} from "../../services/historic-search.service";
import {CalendarModule} from "primeng/calendar";
import {UsersService} from "../../services/users.service";
import {MultiSelectModule} from "primeng/multiselect";
import {SearchResponse} from "../../services/search/search.service";

@Component({
    selector: 'app-search-list',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        RippleModule,
        SharedModule,
        TableModule,
        ReactiveFormsModule,
        DialogModule,
        RouterLink,
        NgIf,
        ReportLinkDirective,
        DatePipe,
        PaginatorModule,
        CalendarModule,
        MultiSelectModule
    ],
    templateUrl: './historic-searches.component.html',
    styleUrl: './historic-searches.component.scss'
})

export class HistoricSearchesComponent implements OnInit {
    searches: Array<SearchResponse> = [];
    cols: any[] = [];
    selectedUsers: Array<any> = [];
    loading:boolean;
    rangeDates: Date[] | null = null;
    users: Array<any>;
    dateFilter: any;
    date:any;

    constructor(private historicSearchService: HistoricSearchService,
                private route: ActivatedRoute,
                private usersService: UsersService) { }

    ngOnInit() {
        this.usersService.getCompanyUsers().subscribe(users => {
            this.users = users;
        });
    }

    getSearches() {

        this.loading = true;

        if(this.selectedUsers === null) {
            this.selectedUsers = [];
        }

        this.historicSearchService.getHistoricSearches(this.selectedUsers, this.dateFilter).subscribe(searches => {
            this.searches = searches;
            this.loading = false;
        });
    }

}
