import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {EmployeeInterface} from "../interfaces/employee";

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {

    constructor(private http: HttpClient) { }

    public getEmployees =  (coincidences: boolean = false, matchFilter: Array<string> = []): Observable<EmployeeInterface[]> => {

        let httpParams = new HttpParams();

        if (coincidences) {
            httpParams = httpParams.append('match', true);
        }

        if (matchFilter.length > 0) {
            httpParams = httpParams.append('matchFilter', JSON.stringify(matchFilter));
        }

        return this.http.get<EmployeeInterface[]>('employees', {
            params: httpParams
        });
    }

    public createEmployee = (employee:EmployeeInterface): Observable<EmployeeInterface> => {
        return this.http.post<EmployeeInterface>('employees', {
            name: employee.name,
            last_name: employee.last_name,
            type_nui: employee.type_nui,
            nui: employee.nui,
            type: employee.type,
            dob: employee.dob
        });
    }

    public updateEmployee = (employee:EmployeeInterface): Observable<EmployeeInterface> => {
        return this.http.put<EmployeeInterface>(`employees/${employee.uuid}`,{
            name: employee.name,
            last_name: employee.last_name,
            type_nui: employee.type_nui,
            nui: employee.nui,
            type: employee.type,
            dob: employee.dob
        });
    }

    public deleteEmployee = (uuid: string): Observable<any> => {
        return this.http.delete(`employees/${uuid}`);
    }

    public searchEmployee = (uuid: string, uuids: string[] = null): Observable<any> => {
        if (uuids == null){
            return this.http.post(`employees/${uuid}/search`, {});
        }
        else
        {
            return this.http.post(`employees/multi/search`, {
                uuids:uuids,
            });
        }

    }

    public getEmployee = (uuid:string): Observable<EmployeeInterface> => {
        return this.http.get<EmployeeInterface>(`employees/${uuid}`);
    }

    public importEmployee = (file: File): Observable<EmployeeInterface> => {
        const formData = new FormData();
        formData.append('file', file);
        const headers = new HttpHeaders({});

        return this.http.post<EmployeeInterface>('employees/import', formData,{
            headers: headers,
        });
    }

}
