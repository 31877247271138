import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BatchRegistersImportEmployeesInterface} from "../interfaces/batch-registers-import-employees";

@Injectable({
  providedIn: 'root'
})
export class BatchRegistersImportEmployeesService {

    constructor(private http: HttpClient) {}

    public getBatchRegistersImportEmployees = (uuid:string): Observable<BatchRegistersImportEmployeesInterface[]> => {
        return this.http.get<BatchRegistersImportEmployeesInterface[]>(`batch_import_employees/${uuid}`);
    }

}
