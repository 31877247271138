import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {AutoCompleteModule} from "primeng/autocomplete";
import {ButtonModule} from "primeng/button";
import {DialogModule} from "primeng/dialog";
import {InputTextModule} from "primeng/inputtext";
import {MultiSelectModule} from "primeng/multiselect";
import {NgClass, NgIf} from "@angular/common";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {Table, TableModule} from "primeng/table";
import {ToolbarModule} from "primeng/toolbar";
import {ClientInterface} from "../../interfaces/client";
import {ClientsService} from "../../services/clients.service";
import {DropdownModule} from "primeng/dropdown";
import {CalendarModule} from "primeng/calendar";
import * as moment from "moment";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {ToastModule} from "primeng/toast";
import {NotificationService} from "../../services/notification-service.service";
import {catchError} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {TagModule} from "primeng/tag";
import {TabViewModule} from "primeng/tabview";
import {LoadSearchCountAction} from "../../store/user/search-count/search-count.actions";
import {Store} from "@ngxs/store";
import {SearchCountResponse} from "../../interfaces/search-count";
import {SearchCountState} from "../../store/user/search-count/search-count.state";
import {CheckboxModule} from "primeng/checkbox";
import {matchFilters} from "../../commons";


interface Type {
    name: string;
    value: string;
}

@Component({
    selector: 'app-clients',
    standalone: true,
    imports: [
        AutoCompleteModule,
        ButtonModule,
        DialogModule,
        InputTextModule,
        MultiSelectModule,
        NgIf,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToolbarModule,
        DropdownModule,
        FormsModule,
        NgClass,
        CalendarModule,
        RouterLink,
        ToastModule,
        TagModule,
        TabViewModule,
        CheckboxModule
    ],
    templateUrl: './clients.component.html',
    styleUrl: './clients.component.scss',
    providers: [NotificationService, MessageService]
})
export class ClientsComponent implements OnInit{

    clients: ClientInterface[];
    selectedClients: ClientInterface[] = [];
    coincidences: boolean;

    nuiTypes: Type[] = [];
    clientTypes: Type[] = [];
    cols: any[] = [];
    clientForm: FormGroup;
    importClientsForm: FormGroup;
    file: File = undefined;

    submitted : boolean = false;
    clientDialog : boolean = false;
    deleteClientsDialog : boolean = false;
    importClientsDialog : boolean = false;

    matchFilters = matchFilters;
    matchFiltersSelected = [];

    searchCount$: Observable<SearchCountResponse> = inject(Store).select(SearchCountState.getState);
    searchCount: SearchCountResponse;

    @ViewChild('file') inputFile: ElementRef;

    constructor(private clientService: ClientsService,
                private messageService: MessageService,
                private store: Store,
                private route: ActivatedRoute,
                private notificationService: NotificationService) {
    }

    ngOnInit() {

        this.route.queryParamMap.subscribe(params => {
            if (params.get('match')) {
                this.coincidences = true;
            }
        });

        this.createForm();
        this.loadClients();
        this.nuiTypes = [
            { name: 'Cédula', value: 'cedula' },
            { name: 'RUC', value: 'ruc' }
        ];
        this.clientTypes = [
            { name: 'Individuo', value: 'person' },
            { name: 'Compañía', value: 'company' }
        ];
        this.importClientsForm = new FormGroup({
            file:new FormControl(''),
        });
    }

    loadClients(){

        if(!this.coincidences) {
            this.matchFiltersSelected = [];
        }

        this.clientService.getClients(this.coincidences, this.matchFiltersSelected).subscribe(clients => {
            this.clients = clients;
        });
    }

    openNew(){
        this.createForm();
        this.submitted = false;
        this.clientDialog = true;
    }

    editSelectedClients(client:ClientInterface) {
        this.clientForm.patchValue({
            name: client.name,
            last_name: client.last_name,
            type_nui: client.type_nui,
            nui: client.nui,
            type: client.type,
            dob: moment(client.dob, 'YYYY-MM-DD').toDate(),
            uuid: client.uuid
        });
        this.submitted = false;
        this.clientDialog = true;
    }

    createForm(){
        this.clientForm = new FormGroup({
            'name': new FormControl(""),
            'last_name': new FormControl(""),
            'type_nui': new FormControl(""),
            'nui': new FormControl(""),
            'type': new FormControl(""),
            'dob': new FormControl(""),
            'uuid': new FormControl("")
        });
    }

    createImportForm(){
        this.importClientsForm = new FormGroup({
           'file': new FormControl('')
        });
    }

    closeModal(){
        this.hideDialog();
        this.importClientsDialog = false;
        this.loadClients();
    }

    hideDialog() {
        this.clientDialog = false;
        this.submitted = false;
    }

    createClient(){
        if (this.clientForm.valid){
            this.clientService.createClient(this.clientForm.value).pipe(
                catchError(error => {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Límite de Búsquedas',
                        detail: 'Su cupo contratado ha sido alcanzado.'
                    })
                    return of(null);
                })
            ).subscribe(data => {
                this.hideDialog();
                this.loadClients();
            });
        }
    }

    updateClient() {
        this.submitted = true;
        if (this.clientForm.valid) {
            this.clientService.updateClient(this.clientForm.value).subscribe(data =>{
                this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Clientes Actualizado', life: 3000 });
                this.hideDialog();
                this.loadClients();
            });
        }
    }

    performAction(){
        if (this.clientForm.get('uuid').value.length == 0)
        {
            this.createClient()
        }
        else {
            this.updateClient()
        }
    }

    deleteSelectedUsers() {
        this.deleteClientsDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteClientsDialog = false;
        this.selectedClients.forEach((val) => {
            this.clientService.deleteClient(val.uuid.toString()).subscribe(()=> {
                this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Clientes Eliminados', life: 3000 });
                this.loadClients();
            });
        });
        this.selectedClients = [];
    }

    searchClient(client: ClientInterface, showNotification: boolean = true){
        client.loading = true;
        if (showNotification) {
            this.clientService.searchClient(client.uuid).subscribe(()=>{
                this.notificationService.showWarnSearched('El registro se encuentra en búsqueda');
                client.loading = false;
            });
        }
    }

    searchSelectedClients(){
        if (this.selectedClients.length === 0) {
            this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'No se han seleccionado clientes.', life: 5000 });
            return;
        }

        const uuids: string[] = this.selectedClients.map(client => client.uuid);
        this.clientService.searchClient(null, uuids).subscribe( () => {
            this.notificationService.showWarnSearched('Los registros se encuentran en búsqueda');
        });
    }

    importClients(){
        this.createImportForm();
        this.submitted = false;
        this.importClientsDialog = true;
    }

    selectFile(event: Event) {
        const input = event.target as HTMLInputElement;
        this.file = input.files[0];
    }

    importList()
    {
        if (this.importClientsForm.value)
        {
            this.clientService.importClients(
                this.file
            ).pipe(
                catchError(error => {
                    this.notificationService.showWarnIncorrectEmailOrPass('La cantidad de búsquedas contratadas ha sido superada.');
                    return of(null);
                })
            ).subscribe(data => {
                setTimeout(() => {
                    this.store.dispatch(new LoadSearchCountAction());
                }, 2000);
                this.importClientsForm.reset();
                this.inputFile.nativeElement.value = '';
                this.file = undefined;
                this.importClientsDialog = false;
            });
        }
    }

    onGlobalFilter(table: Table, event: Event) {
        table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
    }

}
