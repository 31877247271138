import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {SearchResponse} from "./search/search.service";
import * as moment from "moment";

@Injectable({
    providedIn: 'root'
})
export class HistoricSearchService {

    constructor(private http: HttpClient) {
    }

    public getHistoricSearches = (users: Array<string>, dates: Array<string | null> | null): Observable<Array<SearchResponse>> => {
        let params = new HttpParams();

        if (dates && dates.length > 0) {
            params = params.append('start', moment(dates[0]).format('YYYY-MM-DD'));
            params = params.append('end', moment(dates[1]).format('YYYY-MM-DD'));
        }

        if (users.length > 0) {
            params = params.append('users', users.join(','));
        }

        return this.http.get<Array<SearchResponse>>(`historic_search`, {params});
    }

}
