<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-table [value]="searches"
                     [columns]="cols"
                     responsiveLayout="scroll"
                     [rows]="10"
                     [paginator]="true"
                     [rowsPerPageOptions]="[10,20,50]"
                     selectionMode="multiple"
                    [loading]="loading">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Historial de Búsquedas</h5>

                        <div class="block ml-4">

                            <p-calendar [(ngModel)]="dateFilter"
                                 selectionMode="range"
                                        placeholder="Fechas (rango)"
                            >
                            </p-calendar>

                            <p-multiSelect [options]="users" optionLabel="header"
                                           [(ngModel)]="selectedUsers"
                                           [optionLabel]="'name'"
                                           [optionValue]="'uuid'"
                                           maxSelectedLabels="1"
                                           defaultLabel="Seleccionar Usuario"
                                           [showClear]="true" class="w-full sm:w-auto">
                            </p-multiSelect>

                            <span *ngIf="false" class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Buscar..."  class="w-full sm:w-auto"/>
                            </span>

                            <p-button label="Filtrar" (onClick)="getSearches()" severity="secondary" />

                        </div>

                    </div>
                </ng-template>
                <ng-template pTemplate="header">

                    <tr>
                        <th>Nombre (Identificación)</th>
                        <th>Usuario</th>
                        <th>Creación</th>
                        <th>Acciones</th>
                    </tr>

                </ng-template>
                <ng-template pTemplate="body" let-search>


                    <tr>
                        <td>{{search.query.name}} {{search.query.last_name}} <span *ngIf="search.query.id && ((search.query.id).length > 0)">( {{search.query.id}} )</span> </td>
                        <td>{{search.user}}</td>
                        <td>{{search.created_at | date: 'yyyy/MM/dd hh:mm a'}}</td>
                        <td>
                            <button pButton pRipple label="Detalles"
                                    icon="pi pi-angle-double-right"
                                    iconPos="right"
                                    class="p-button-raised p-button-green mr-3  "
                                    [routerLink]="['/search', search.uuid]">
                            </button>

                            <a *ngIf="!!search?.report" target="_blank"
                               [reportLink]="search.report"
                               [uuid]="search.uuid"
                               href="{{search.report}}">
                                <p-button label="Descargar Informe"
                                          icon="pi pi-download"
                                          class="p-button-warning"
                                          severity="success">
                                </p-button>
                            </a>
                        </td>
                    </tr>

                </ng-template>
            </p-table>
        </div>
    </div>
</div>
