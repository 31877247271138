import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BatchImportEmployeeInterface} from "../interfaces/batch-import-employee";

@Injectable({
  providedIn: 'root'
})
export class BatchImportEmployeesService {

    constructor(private http: HttpClient) {}

    public getBatchImportEmployees = (): Observable<BatchImportEmployeeInterface[]> => {
        return this.http.get<BatchImportEmployeeInterface[]>('batch_import_employees');
    }

}
