import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {MinisterioTrabajoInterface} from "../../interfaces/ministerio-trabajo/ministerio-trabajo";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-ministerio-trabajo-display',
  standalone: true,
    imports: [
        SharedModule,
        TableModule,
        NgIf
    ],
  templateUrl: './ministerio-trabajo-display.component.html',
  styleUrl: './ministerio-trabajo-display.component.scss'
})
export class MinisterioTrabajoDisplayComponent {

    @Input() records: MinisterioTrabajoInterface[] = [];

}
