import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BatchRegistersImportClientsInterface} from "../interfaces/batch-registers-import-clients";

@Injectable({
  providedIn: 'root'
})
export class BatchRegistersImportClientsService {

    constructor(private http: HttpClient) {}

    public getBatchRegistersImportClients = (uuid:string): Observable<BatchRegistersImportClientsInterface[]> => {
        return this.http.get<BatchRegistersImportClientsInterface[]>(`batch_import_clients/${uuid}`);
    }

}
