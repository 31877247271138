import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BatchRegistersImportSuppliersInterface} from "../interfaces/batch-registers-import-suppliers";
import {BatchImportSupplierInterface} from "../interfaces/batch-import-supplier";

@Injectable({
  providedIn: 'root'
})
export class BatchImportSuppliersService {

    constructor(private http: HttpClient) {}

    public getBatchImportSuppliers = (): Observable<BatchImportSupplierInterface[]> => {
        return this.http.get<BatchImportSupplierInterface[]>('batch_import_suppliers');
    }

}
