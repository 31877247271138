import {Component, OnInit} from '@angular/core';
import {BatchRegistersImportClientsService} from "../../../../services/batch-registers-import-clients.service";
import {BatchRegistersImportClientsInterface} from "../../../../interfaces/batch-registers-import-clients";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-batch-registers-import',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        SharedModule,
        TableModule
    ],
    templateUrl: './batch-registers-import.component.html',
    styleUrl: './batch-registers-import.component.scss'
})
export class BatchRegistersImportClientsComponent implements OnInit{

    batch_registers_import_clients: BatchRegistersImportClientsInterface[];
    cols: any[] = [];
    uuid: string | null = null;

    constructor(private batchRegistersImportService: BatchRegistersImportClientsService,
                private route:ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.uuid = params['uuid'];
            this.loadBatchRegistersImport();
        });
    }

    loadBatchRegistersImport(){
        this.batchRegistersImportService.getBatchRegistersImportClients(this.uuid).subscribe(batch_registers_import_clients => {
            this.batch_registers_import_clients = batch_registers_import_clients;
        });
    }

}
