import {Component, OnInit} from '@angular/core';
import {BatchImportSupplierInterface} from "../../../interfaces/batch-import-supplier";
import {BatchImportSuppliersService} from "../../../services/batch-import-suppliers.service";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";
import {RippleModule} from "primeng/ripple";

@Component({
    selector: 'app-batch-import',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        SharedModule,
        TableModule,
        RouterLink,
        NgIf,
        RippleModule
    ],
    templateUrl: './batch-import.component.html',
    styleUrl: './batch-import.component.scss'
})
export class BatchImportComponent implements OnInit{

    batch_import_suppliers: BatchImportSupplierInterface[];
    cols: any[] = [];

    constructor(private batchImportService: BatchImportSuppliersService) {
    }

    ngOnInit() {
        this.loadBatchImport();
    }

    loadBatchImport(){
        this.batchImportService.getBatchImportSuppliers().subscribe(batch_import_suppliers => {
            this.batch_import_suppliers = batch_import_suppliers;
        });
    }

}
