import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {InputTextModule} from "primeng/inputtext";
import {ButtonModule} from "primeng/button";
import {DividerModule} from "primeng/divider";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import {InterpolDisplayComponent} from "../../components/interpol-display/interpol-display.component";
import {DatePipe, NgClass, NgForOf, NgIf} from "@angular/common";
import {AccordionModule} from "primeng/accordion";
import {AvatarModule} from "primeng/avatar";
import {BadgeModule} from "primeng/badge";
import {CausasDisplayComponent} from "../../components/causas-display/causas-display.component";
import {PenasDisplayComponent} from "../../components/penas-display/penas-display.component";
import {SearchResponse, SearchService} from "../../services/search/search.service";
import {Router, RouterLink} from "@angular/router";
import {Table, TableModule} from "primeng/table";
import {RippleModule} from "primeng/ripple";
import {Store} from "@ngxs/store";
import {LoadCurrentUserAction} from "../../store/user/store/user.actions";
import {UserState} from "../../store/user/store/user.state";
import {Observable, of, Subscription, throwError} from "rxjs";
import {User} from "../../interfaces/user";
import {TabViewModule} from "primeng/tabview";
import {SearchCountResponse} from "../../interfaces/search-count";
import {SearchCountState} from "../../store/user/search-count/search-count.state";
import {LoadSearchCountAction} from "../../store/user/search-count/search-count.actions";
import {NotificationService} from "../../services/notification-service.service";
import {catchError} from "rxjs/operators";
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import {CalendarModule} from "primeng/calendar";

@Component({
    standalone: true,
    imports: [
        InputTextModule,
        ButtonModule,
        InterpolDisplayComponent,
        DividerModule,
        ReactiveFormsModule,
        NgForOf,
        AccordionModule,
        AvatarModule,
        BadgeModule,
        NgClass,
        NgIf,
        CausasDisplayComponent,
        PenasDisplayComponent,
        TableModule,
        RouterLink,
        DatePipe,
        RippleModule,
        TabViewModule,
        ToastModule,
        CalendarModule
    ],
    templateUrl: './search.component.html',
    styleUrl: './search.component.scss',
    providers: [NotificationService, MessageService]
})
export class SearchComponent implements OnInit, OnDestroy {

    form: FormGroup;
    formEntidad: FormGroup;
    historicSearch: SearchResponse[];
    user$: Observable<User> = inject(Store).select(UserState.currenUser);
    user: User;
    userSubscription: Subscription;
    activeIndex: number = 0;
    submitted = false;

    searchCount$: Observable<SearchCountResponse> = inject(Store).select(SearchCountState.getState);
    searchCount: SearchCountResponse;

    constructor(private searchService: SearchService,
                private fb: FormBuilder,
                private router: Router,
                private store:Store,
                private notificationService: NotificationService)
    {}

    ngOnInit() {
        this.store.dispatch(new LoadSearchCountAction());
        this.searchCount$.subscribe(count=> {
            this.searchCount = count;
        });
        this.form = this.fb.group({
            'name': ['', Validators.required],
            'last_name': ['', Validators.required],
            'id': [''],
            'dob': [''],
        });
        this.formEntidad = this.fb.group({
            'name': ['', Validators.required],
            'ruc': [''],
        });

        this.searchService.loadSearch().subscribe(historicSearch => {
            this.historicSearch = historicSearch;
        });

        this.userSubscription = this.user$.subscribe(user => {
            if(user.email.length === 0) {
                this.store.dispatch(new LoadCurrentUserAction());
                return;
            }

            console.log('execute subscription');
            console.log(user);
            this.user = user;
        });
    }

    search = () => {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }

        const name = this.form.get('name').value;
        const lastName = this.form.get('last_name').value;
        const id = this.form.get('id').value;
        const dob = this.form.get('dob').value;

        this.searchService.search(name, lastName, id, dob).pipe(
            catchError(error => {
                this.notificationService.showWarnIncorrectEmailOrPass('Su cantidad de búsquedas contratadas ha sido superada.');
                return of(null);
            })
        ).subscribe(
            searchResponse => {
                this.store.dispatch(new LoadCurrentUserAction());
                this.router.navigate(['search', searchResponse.uuid]).then();
            }
        );

    }

    searchEntity = () => {
        this.submitted = true;
        if (this.formEntidad.invalid) {
            return;
        }

        const name = this.formEntidad.get('name').value;
        const lastName = "";
        const id = this.formEntidad.get('ruc').value;
        const dob = "";

        this.searchService.search(name, lastName, id, dob).subscribe(searchResponse => {
            this.store.dispatch(new LoadCurrentUserAction());
            this.router.navigate(['search', searchResponse.uuid]).then();
        });
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    onGlobalFilter(table: Table, event: Event) {
        table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
    }
}
