import {Component, OnInit} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {CheckboxModule} from "primeng/checkbox";
import {InputTextModule} from "primeng/inputtext";
import {PasswordModule} from "primeng/password";
import {LayoutService} from "../../../layout/service/app.layout.service";
import {AuthService} from "../../../services/auth.service";
import {Router} from "@angular/router";
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, RequiredValidator, Validators} from "@angular/forms";
import {Store} from "@ngxs/store";
import {LoadCurrentUserAction} from "../../../store/user/store/user.actions";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { NgIf } from "@angular/common";
import {NotificationService} from "../../../services/notification-service.service";
import {MessageService} from "primeng/api";
import {ToastModule} from "primeng/toast";
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        ButtonModule,
        CheckboxModule,
        InputTextModule,
        PasswordModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        NgIf,
        ToastModule
    ],
    providers: [NotificationService, MessageService],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    valCheck: string[] = ['remember'];
    email!: string;
    password!: string;
    form: FormGroup;
    isLoading: boolean = false;

    constructor(
        public layoutService: LayoutService,
        private authService: AuthService,
        private router: Router,
        private store: Store,
        private notificationService: NotificationService
    ) {
        this.form = new FormGroup({
            email: new FormControl('', Validators.required),
            password: new FormControl('', Validators.required),
        });
    }


    protected readonly LoginComponent = LoginComponent;

    login() {

        if (this.form.invalid) {
            this.isLoading = false;
            this.notificationService.showWarnEmailOrPassMandatory('Los campos de email y contraseña son obligatorios.');
            return;
        }

        this.isLoading = true;
        this.authService.login(
            this.form.get('email').value.toString(),
            this.form.get('password').value.toString()
        ).pipe(
            catchError(error => {
                this.isLoading = false;
                this.notificationService.showWarnIncorrectEmailOrPass('Usuario o Contraseña Incorrecta.');
                this.form.get('email').reset();
                this.form.get('password').reset();
                return of(null);
            })
        ).subscribe(
            data => {
                if (data) {
                    this.isLoading = false;
                    sessionStorage.setItem("usrToken", data.token);
                    this.store.dispatch(new LoadCurrentUserAction());

                    if (data.plan.plan_type == 'Clientes'){
                        this.router.navigate(['/']).then();
                    }
                    else
                    {
                        this.router.navigate(['/search']).then();
                    }


                }
            }
        );
    }

    resetPassword = () => {
        this.router.navigate(['/reset_email']).then();
    }

    /*formGroup = new FormGroup({
        email: email = new FormGroup(''),
        password: new FormGroup('')
    });

    clickRegister(): void{
        const email = this.formGroup.controls.email.value;
        console.log(email);
    }*/

}
