import { Component, OnInit } from '@angular/core';
import {BatchImportEmployeesService} from "../../../services/batch-import-employees.service";
import {BatchImportEmployeeInterface} from "../../../interfaces/batch-import-employee";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {RouterLink} from "@angular/router";
import {NgIf} from "@angular/common";
import {RippleModule} from "primeng/ripple";

@Component({
    selector: 'app-batch-import',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        SharedModule,
        TableModule,
        RouterLink,
        NgIf,
        RippleModule
    ],
    templateUrl: './batch-import.component.html',
    styleUrl: './batch-import.component.scss'
})
export class BatchImportEmployeesComponent implements OnInit{

    batch_import_employees: BatchImportEmployeeInterface[];
    cols: any[] = [];

    constructor(private batchImportService: BatchImportEmployeesService) {
    }

    ngOnInit() {
        this.loadBatchImport();
    }

    loadBatchImport(){
        this.batchImportService.getBatchImportEmployees().subscribe(batch_import_employees => {
            this.batch_import_employees = batch_import_employees;
        });
    }

}
