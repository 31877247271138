<div>
    <p-table styleClass="p-datatable-gridlines" responsiveLayout="scroll" [value]="records" rows="10" paginator="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Cédula
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Compañia
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Cargo
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Año
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Declaración
                    </div>
                </th>
        </ng-template>

        <ng-template pTemplate="body" let-record>
            <tr>
                <td>
                    {{record.nui}}
                </td>
                <td>
                    {{record.name}}
                </td>
                <td>
                    {{record.company}}
                </td>
                <td>
                    {{record.position}}
                </td>
                <td>
                    {{record.year}}
                </td>
                <td>
                    <a href="https://www.contraloria.gob.ec/sistema/WFDeclaracionAcuerdo024.aspx?xx=1738766429665&id={{record.nui}}&td={{record.id}}&op=d" target="_blank">
                        <i class="pi pi-fw pi-file-pdf" style="color: darkred;"></i>Descarga
                    </a>
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
