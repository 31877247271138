import {inject, OnInit} from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import {Observable} from "rxjs";
import {User} from "../interfaces/user";
import {Store} from "@ngxs/store";
import {UserState} from "../store/user/store/user.state";
import {LoadCurrentUserAction} from "../store/user/store/user.actions";
import {SearchCountResponse} from "../interfaces/search-count";
import {SearchCountState} from "../store/user/search-count/search-count.state";

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];
    user$: Observable<User> = inject(Store).select(UserState.currenUser);
    user: User;
    searchCount$: Observable<SearchCountResponse> = inject(Store).select(SearchCountState.getState);
    searchCount: SearchCountResponse;

    constructor(public layoutService: LayoutService, private store: Store) { }

    drawModel(items): void {
        this.model = [
            {
                label: 'Menú Principal',
                items: items
            }
        ];
    }

    renderMenu ()
    {
        const items = [];

        if (this.searchCount.plan_type == 'Clientes') {
            items.push(
                { label: 'Panel Principal', icon: 'pi pi-fw pi-home', routerLink: ['/'] }
            );
        }

        if (!(this.searchCount.plan_type == 'Clientes')) {
            items.push(
                { label: 'Búsqueda Individual', icon: 'pi pi-fw pi-search', routerLink: ['/search'] }
            );
            items.push(
                { label: 'Búsqueda Masiva', icon: 'pi pi-fw pi-search', routerLink: ['/batch_search'] }
            );
        }

        if (this.user.permissions.includes('Clients show') && (this.searchCount.plan_type == 'Clientes')) {
            items.push ({ label: 'Clientes', icon: 'pi pi-fw pi-users', routerLink: ['/clients'] });
        }

        if (this.user.permissions.includes('Suppliers show') && (this.searchCount.plan_type == 'Clientes')) {
            items.push ({ label: 'Proveedores', icon: 'pi pi-cart-plus', routerLink: ['/suppliers'] });
        }

        if (this.user.permissions.includes('Employees show') && (this.searchCount.plan_type == 'Clientes')) {
            items.push ({ label: 'Empleados', icon: 'pi pi-fw pi-thumbs-up', routerLink: ['/employees'] });
        }

        if (this.user.permissions.includes('Blacklist show')) {
            items.push ({ label: 'Listas', icon: 'pi pi-fw pi-list', routerLink: ['/lists'] });
        }

        if (this.user.permissions.includes('Users show')) {
            items.push ({ label: 'Usuarios', icon: 'pi pi-fw pi-user', routerLink: ['/users'] });
        }

        if (this.user.permissions.includes('Groups show')) {
            items.push ({ label: 'Grupos', icon: 'pi pi-fw pi-sitemap', routerLink: ['/groups'] });
        }

        if (this.user.permissions.includes('Permissions show')) {
            items.push ({ label: 'Permisos', icon: 'pi pi-fw pi-shield', routerLink: ['/permissions'] });
        }

        if (this.user.permissions.includes('Roles show')) {
            items.push ({ label: 'Roles', icon: 'pi pi-fw pi-user-plus', routerLink: ['/roles'] });
        }

        if (this.user.permissions.includes('Read All Company Search')) {
            items.push ({ label: 'Historial de búsquedas', icon: 'pi pi-fw pi-clock', routerLink: ['/historic_search'] });

        }

        this.drawModel(items);

    }

    ngOnInit() {

        this.store.dispatch(new LoadCurrentUserAction());

        this.searchCount$.subscribe(count=> {
            this.searchCount = count;
        });

        this.user$.subscribe(user => {
            this.user = user;
            this.renderMenu();
        });

    }
}
