<div *ngIf="records.length > 0">

    <div class="container">
        <div class="row">

            <div class="col-4 cell">
                <p><b>RUC:</b> {{records[0].contribuyente.numeroRuc}}</p>
            </div>
            <div class="col-4 cell">
                <p><b>Razón social:</b> {{records[0].contribuyente.razonSocial}}</p>
            </div>
            <div class="col-4 cell">
                <p><b>Estado contribuyente en el RUC: </b> <span class="enfasis">{{records[0].contribuyente.estadoContribuyenteRuc}}</span></p>
            </div>

        </div>

        <div class="row">

            <div class="col-4 cell">
                <div *ngIf="records?.[0]?.contribuyente?.estadoContribuyenteRuc == 'ACTIVO'; else elseBlock">
                    <p><b>Actividad económica principal: </b> {{records[0].contribuyente.actividadEconomicaPrincipal}}</p>
                </div>
                <ng-template #elseBlock>
                    <p><b>Motivo suspensión: </b> {{records[0].contribuyente.motivoCancelacionSuspension}}</p>
                </ng-template>
            </div>
            <div class="col-4 cell">
                <b>Contribuyente fantasma:</b> {{records[0].contribuyente.contribuyenteFantasma}}
            </div>
            <div class="col-4 cell">
                <b>Contribuyente con transacciones inexistentes:</b> {{records[0].contribuyente.transaccionesInexistente}}
            </div>

        </div>
    </div>



    <p-divider class="p-element"></p-divider>
    <div class="container">
        <div class="row">
            <div class="col-4 cell">
                <p class="tit-blue">Tipo contribuyente</p>
                {{records[0].contribuyente.tipoContribuyente}}
            </div>
            <div class="col-4 cell">
                <p class="tit-blue">Régimen</p>
                {{records[0].contribuyente.regimen}}
            </div>
            <div class="col-4 cell">
                <p class="tit-blue">Categoría</p>
                {{records[0].contribuyente.categoria}}
            </div>
        </div>
        <div class="row">
            <div class="col-4 cell">
                <p class="tit-blue">Obligado a llevar contabilidad</p>
                {{records[0].contribuyente.obligadoLlevarContabilidad}}
            </div>
            <div class="col-4 cell">
                <p class="tit-blue">Agente de retención</p>
                {{records[0].contribuyente.agenteRetencion}}
            </div>
            <div class="col-4 cell">
                <p class="tit-blue">Contribuyente especial</p>
                {{records[0].contribuyente.contribuyenteEspecial}}
            </div>
        </div>
        <div class="row">
            <div class="col-3 cell">
                <p class="tit-blue">Fecha inicio actividades</p>
                {{records[0].contribuyente.informacionFechasContribuyente.fechaInicioActividades | date:'yyyy-MM-dd' }}
            </div>
            <div class="col-3 cell">
                <p class="tit-blue">Fecha actualización</p>
                {{records[0].contribuyente.informacionFechasContribuyente.fechaActualizacion | date:'yyyy-MM-dd' }}
            </div>
            <div class="col-3 cell">
                <p class="tit-blue">Fecha cese actividades</p>
                {{records[0].contribuyente.informacionFechasContribuyente.fechaCese | date:'yyyy-MM-dd' }}
            </div>
            <div class="col-3 cell">
                <p class="tit-blue">Fecha reinicio actividades</p>
                {{records[0].contribuyente.informacionFechasContribuyente.fechaReinicioActividades | date:'yyyy-MM-dd' }}
            </div>
        </div>
    </div>

    <p-table responsiveLayout="scroll" [value]="records[0].locales" [rows]="10" [paginator]="true" styleClass="p-datatable-gridlines" >

        <ng-template pTemplate="header">
            <tr>
                <th colspan="4">
                    <div class="tit">
                        Lista de establecimientos | {{ records[0].locales.length }} registro(s)
                    </div>
                </th>
            </tr>
            <tr>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        No. establecimiento
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Nombre comercial
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Ubicación de establecimiento
                    </div>
                </th>
                <th style="min-width: 12rem">
                    <div class="flex justify-content-between align-items-center">
                        Estado del establecimiento
                    </div>
                </th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-local>
            <tr>
                <td>
                    {{local.numeroEstablecimiento}}
                </td>
                <td>
                    {{local.nombreFantasiaComercial}}
                </td>
                <td>
                    {{local.direccionCompleta}}
                </td>
                <td>
                    {{local.estado}}
                </td>
            </tr>
        </ng-template>

    </p-table>
</div>
