import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {SupplierInterface} from "../interfaces/supplier";


@Injectable({
  providedIn: 'root'
})
export class SuppliersService {

    constructor(private http: HttpClient) { }

    public getSuppliers = (coincidences: boolean = false, matchFilter: Array<string> = []): Observable<SupplierInterface[]> => {
        let httpParams = new HttpParams();

        if (coincidences) {
            httpParams = httpParams.append('match', true);
        }

        if (matchFilter.length > 0) {
            httpParams = httpParams.append('matchFilter', JSON.stringify(matchFilter));
        }

        return this.http.get<SupplierInterface[]>('suppliers', {
            params: httpParams
        });
    }

    public createSupplier = (supplier:SupplierInterface): Observable<SupplierInterface> => {
        return this.http.post<SupplierInterface>('suppliers', {
            name: supplier.name,
            last_name: supplier.last_name,
            type_nui: supplier.type_nui,
            nui: supplier.nui,
            type: supplier.type,
            dob: supplier.dob
        });
    }

    public updateSupplier = (supplier:SupplierInterface): Observable<SupplierInterface> => {
        return this.http.put<SupplierInterface>(`suppliers/${supplier.uuid}`,{
            name: supplier.name,
            last_name: supplier.last_name,
            type_nui: supplier.type_nui,
            nui: supplier.nui,
            type: supplier.type,
            dob: supplier.dob
        });
    }

    public deleteSupplier = (uuid: string): Observable<any> => {
        return this.http.delete(`suppliers/${uuid}`);
    }

    public searchSupplier = (uuid: string, uuids: string[] = null): Observable<any> => {
        if (uuids == null){
            return this.http.post(`suppliers/${uuid}/search`, {});
        }
        else
        {
            return this.http.post(`suppliers/multi/search`, {
                uuids:uuids,
            });
        }

    }

    public getSupplier = (uuid:string): Observable<SupplierInterface> => {
        return this.http.get<SupplierInterface>(`suppliers/${uuid}`);
    }

    public importSuppliers = (file: File): Observable<SupplierInterface> => {
        const formData = new FormData();
        formData.append('file', file);
        const headers = new HttpHeaders({});

        return this.http.post<SupplierInterface>('suppliers/import', formData,{
            headers: headers,
        });
    }

}
