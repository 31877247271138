<p-toast [life]="5500"></p-toast>
<div class="grid">
    <div class="col-12">
        <div class="card px-6 py-6">

            <p-toolbar styleClass="mb-4">
                <ng-template pTemplate="left">
                    <div class="my-2">
                        <button pButton pRipple label="Crear Empleado" icon="pi pi-plus"
                                class="p-button mr-2" (click)="openNew()">
                        </button>

                        <button pButton pRipple label="Eliminar Empleado" icon="pi pi-trash"
                                class="p-button mr-2 mt-2" (click)="deleteSelectedEmployees()">
                        </button>

                        <button pButton pRipple label="Buscar Seleccionados" icon="pi pi-search"
                                class="p-button-secondary mr-2 mt-2"
                                (click)="searchSelectedEmployees()">
                        </button>

                        <button pButton pRipple label="Importar Empleados" icon="pi pi-cloud-upload"
                                class="p-button-secondary mr-2 mt-2"
                                (click)="importEmployees()">
                        </button>

                        <button pButton pRipple label="Estado de Importación" icon="pi pi-check"
                                class="p-button-secondary mr-2 mt-2"
                                [routerLink]="['/batch_import_employees']">
                        </button>
                    </div>
                </ng-template>
            </p-toolbar>

            <p-table [value]="employees"
                     #dt
                     [globalFilterFields]="['name','last_name','nui']"
                     [columns]="cols" responsiveLayout="scroll"
                     [rows]="10"
                     [paginator]="true"
                     [(selection)]="selectedEmployees"
                     selectionMode="multiple">

                <ng-template pTemplate="caption">
                    <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                        <h5 class="m-0">Lista de Empleados</h5>
                        <div class="block mt-2 md:mt-0 flex flex-column md:flex-row md:justify-content-between md:align-items-center">

                            <span class="block  p-input-icon-left">
                                <p-multiSelect
                                    *ngIf="coincidences"
                                    [options]="matchFilters"
                                    (onChange)="loadEmployees()"
                                    [optionLabel]="'name'"
                                    [optionValue]="'id'"
                                    [(ngModel)]="matchFiltersSelected"
                                    [maxSelectedLabels]="1"
                                    placeholder="Seleccionar Canal" />
                            </span>

                            <span class="block ml-4 mt-2 md:mt-0 p-input-icon-left">
                                <p-checkbox
                                    [(ngModel)]="coincidences"
                                    [binary]="true"
                                    (onChange)="loadEmployees()"
                                    label="Coincidencias"
                                    inputId="binary" />
                            </span>
                            <span class="block ml-4 mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Buscar..."  class="w-full sm:w-auto"/>
                            </span>

                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">Nombres y Apellidos<p-sortIcon field="name"></p-sortIcon></th>
                        <th>Acciones</th>

                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-employee>
                    <tr>
                        <td><p-tableCheckbox [value]="employee"></p-tableCheckbox></td>
                        <td>{{employee.last_name}} {{employee.name}}</td>
                        <td>
                            <p-button label="Editar" icon="pi pi-pencil"
                                      class="mr-2 mt-2" (click)="editSelectedEmployees(employee)"></p-button>

                            <p-button label="Buscar Empleado" icon="pi pi-search"
                                      class="mr-2 mt-2" [loading]="employee.loading" (click)="searchEmployee(employee)"></p-button>

                            <p-button label="Historial" icon="pi pi-search"
                                      class="mr-2 mt-2" [routerLink]="['/employees', employee.uuid, 'search_list']"></p-button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>

        </div>


        <p-dialog [formGroup]="employeeForm"
                  [(visible)]="employeeDialog"
                  [style]="{width: '450px'}"
                  [modal]="true"
                  header="Nuevo Empleado"
                  class="p-fluid">

            <ng-template pTemplate="content">
                <div class="field">
                    <label>Tipo de Empleado</label>
                    <p-dropdown
                        [options]="employeeTypes"
                        formControlName="type"
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Selecciona su Tipo" />
                </div>
                <div *ngIf="employeeForm.get('type').value === 'company'" class="field">
                    <label>Razón Social</label>
                    <input type="text" pInputText formControlName="last_name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !employeeForm.get('last_name').valid}"/>
                </div>
                <div *ngIf="employeeForm.get('type').value === 'person'" class="field">
                    <label>Nombres</label>
                    <input type="text" pInputText formControlName="name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !employeeForm.get('name').valid}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !employeeForm.get('name').valid">Nombre es requerido.</small>
                </div>
                <div *ngIf="employeeForm.get('type').value === 'person'" class="field">
                    <label>Apellidos</label>
                    <input type="text" pInputText formControlName="last_name" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !employeeForm.get('last_name').valid}"/>
                    <small class="ng-dirty ng-invalid" *ngIf="submitted && !employeeForm.get('last_name').valid">Apellido es requerido.</small>
                </div>
                <div class="field" *ngIf="employeeForm.get('type').value === 'person'">
                    <label>Fecha de Nacimiento</label>
                    <p-calendar
                        formControlName="dob"
                        [showIcon]="true"
                        [showOnFocus]="false"
                        dateFormat="dd/mm/yy"
                        inputId="buttondisplay"
                        appendTo="body" />
                </div>

                <div *ngIf="employeeForm.get('type').value === 'company'" class="field">
                    <label>Tipo de Identificación</label>
                    <p-dropdown
                        [options]="nuiTypes"
                        formControlName="type_nui"
                        optionLabel="name"
                        optionValue="value"
                        placeholder="Selecciona el Tipo de Identificación" />
                </div>
                <div class="field">
                    <label>Identificación</label>
                    <input type="text" pInputText formControlName="nui"/>
                </div>
            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="closeModal()"></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="performAction()"></button>
            </ng-template>

        </p-dialog>


        <p-dialog [formGroup]="importEmployeesForm"
                  [(visible)]="importEmployeesDialog"
                  [style]="{width: '450px'}"
                  [modal]="true"
                  header="Importar Empleados"
                  class="p-fluid">

            <ng-template pTemplate="content">

                <div class="flex">
                    <div class="col-12 ">
                        <a href="https://apla-docs.s3.us-east-1.amazonaws.com/Ejemplo_de_Importacion_Empleados.xlsx" alt="Descargar Archivo de Ejemplo"
                           title="Descargar Archivo de Ejemplo" target="_blank">
                            <p-tag icon="pi pi-info-circle" severity="info" value="Descargar Archivo de Ejemplo"></p-tag>
                        </a>
                    </div>
                </div>
                <div class="flex">
                    <div class="col-12">
                        <label>Archivo: </label>
                        <input type="file" #file accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                               (change)="selectFile($event)">
                    </div>
                </div>

            </ng-template>

            <ng-template pTemplate="footer">
                <button pButton pRipple label="Cancelar" icon="pi pi-times" class="p-button-text" (click)="closeModal()"></button>
                <button pButton pRipple label="Guardar" icon="pi pi-check" class="p-button-text" (click)="importList()"></button>
            </ng-template>

        </p-dialog>


        <p-dialog [(visible)]="deleteEmployeesDialog" header="Confirmación" [modal]="true" >
            <div class="flex align-items-center justify-content-center">
                <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
                <span>¿Está seguro que desea eliminar el proveedor?</span>
            </div>
            <ng-template pTemplate="footer">
                <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteEmployeesDialog = false"></button>
                <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Si" (click)="confirmDeleteSelected()"></button>
            </ng-template>
        </p-dialog>


    </div>
</div>
