import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BatchImportClientInterface} from "../interfaces/batch-import-client";

@Injectable({
  providedIn: 'root'
})
export class BatchImportClientsService {

    constructor(private http: HttpClient) {}

    public getBatchImportClients = (): Observable<BatchImportClientInterface[]> => {
        return this.http.get<BatchImportClientInterface[]>('batch_import_clients');
    }

}
