import {Component, OnInit} from '@angular/core';
import {CurrencyPipe, NgForOf, NgIf, NgStyle} from "@angular/common";
import {DashboardService} from "../../services/dashboard.service";
import {Dashboard} from "../../interfaces/dashboard";
import {RouterLink} from "@angular/router";
import {ButtonModule} from "primeng/button";
import {MenuModule} from "primeng/menu";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";

@Component({
  selector: 'app-dashboard',
  standalone: true,
    imports: [
        NgStyle,
        RouterLink,
        ButtonModule,
        CurrencyPipe,
        MenuModule,
        SharedModule,
        TableModule,
        NgIf,
        NgForOf
    ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit{

    dashboardData: Dashboard;

    constructor(private dashboardService: DashboardService) {}

    ngOnInit() {
        this.dashboardService.get().subscribe(data => {
            this.dashboardData = data;
        });
    }
}
