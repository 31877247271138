<div class="mt-0">
    <div class="card">
        <h5>Búsqueda ejecutada de Personas/Empresas por nombre y/o identificación</h5>

        <p-divider />

        <div *ngIf="searchResponse" class="grid">
            <div class="col-4 p-fluid">
                <div class="field"><b><label>Nombre(s)</label></b><br />
                    {{ searchResponse.query.name }} {{ searchResponse.query.last_name }}
                </div>
            </div>
            <div class="col-4 p-fluid">
                <div class="field">
                    <b><label>Identificación</label></b><br />
                    {{ searchResponse.query.id }}
                </div>
            </div>
            <div class="col-2 p-fluid">
                <div class="field">
                    <a *ngIf="!!searchResponse?.report" target="_blank"
                       [reportLink]="searchResponse.report"
                       [uuid]="searchResponse.uuid"
                       href="{{searchResponse.report}}">
                        <p-button label="Descargar Informe" icon="pi pi-download"></p-button>
                    </a>
                </div>
            </div>

        </div>

        <div class="flex flex-wrap gap-2">

        </div>

    </div>
</div>

<div class="card mt-5">
    <h5>Búsqueda en línea</h5>
    <p-accordion class="w-full" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">

        <p-accordionTab *ngIf="getInstance('cj_causas'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/ConsejoDeLaJudicatura.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Consejo de la Judicatura (Causas)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-causas-display [causas]="instanceResponse?.response?.records"></app-causas-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a [href]="instanceResponse?.files[0]"
                       [laLink]="instanceResponse?.files[0]"
                       [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('cj_condenas'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/ConsejoDeLaJudicatura.png" shape="circle" />
                    <span class="font-bold white-space-nowrap">Consejo de la Judicatura (Penas)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-penas-display [penas]="instanceResponse?.response?.records"></app-penas-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a [href]="instanceResponse?.files[0]"
                       [laLink]="instanceResponse?.files[0]"
                       [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('peps'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/Peps.png" shape="square" />
                    <span class="font-bold white-space-nowrap">PEPS</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-peps-display [records]="instanceResponse?.response.records"></app-peps-display>
                </div>
            </div>
            <ng-container *ngIf="instanceResponse?.response?.total == 0">
                No se encontraron registros
            </ng-container>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('cne_pep'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/CNE.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Consejo Nacional Electoral (CNE)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-cne-display [records]="instanceResponse?.response.records"></app-cne-display>
                </div>
            </div>
            <ng-container *ngIf="instanceResponse?.response?.total == 0">
                No se encontraron registros
            </ng-container>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('mas_buscados_ec'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/logoOfi.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Mas Buscados EC</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-mas-buscados-ec-display [records]="instanceResponse?.response.records"></app-mas-buscados-ec-display>
                </div>
            </div>
            <ng-container *ngIf="instanceResponse?.response?.total == 0">
                No se encontraron registros
            </ng-container>

            <ng-container *ngIf="instanceResponse?.response?.total > 0">
                <p-divider />
                <a [href]="instanceResponse?.files[0]" target="_blank">
                    Evidencia
                </a>
            </ng-container>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('ofac'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/OFAC-2.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Office of Foreign Assets Control (OFAC)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-ofac-display [records]="instanceResponse?.response.records"></app-ofac-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a [href]="instanceResponse?.files[0]"
                       [laLink]="instanceResponse?.files[0]"
                       [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('nnuu_individual'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/onu.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Consejo de Seguridad de la ONU (Individuos)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem"></p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-nnuu-display [records]="instanceResponse?.response.records"></app-nnuu-display>
                </div>
            </div>
            <ng-container *ngIf="instanceResponse?.response?.total == 0">
                No se encontraron registros
            </ng-container>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('nnuu_entity'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/onu.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Consejo de Seguridad de la ONU (Entidad)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-nnuu-entity-display [records]="instanceResponse?.response.records"></app-nnuu-entity-display>
                </div>
            </div>
            <ng-container *ngIf="instanceResponse?.response?.total == 0">
                No se encontraron registros
            </ng-container>
        </p-accordionTab>



        <p-accordionTab *ngIf="getInstance('interpol'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/intepol.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Interpol</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <ng-container *ngFor="let notice of instanceResponse?.response?.notices">
                        <app-interpol-display [notice]="notice"></app-interpol-display>
                    </ng-container>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a [href]="instanceResponse?.files[0]"
                       [laLink]="instanceResponse?.files[0]"
                       [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('empresas_fantasma_ec'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/SRI.jpg" shape="square" />
                    <span class="font-bold white-space-nowrap">Empresas Fantasma EC</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">

                    <app-shell-companies-display [records]="instanceResponse?.response.records"></app-shell-companies-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
            </div>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('iess_certificado_afiliacion'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/iess.png" shape="square" />
                    <span class="font-bold white-space-nowrap">IESS</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="!instanceResponse?.response?.contribution ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.status" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response">

                    <app-iess-display [afiliado]="instanceResponse?.response"></app-iess-display>
                </div>

                <ng-container *ngIf="!instanceResponse?.response?.contribution">
                    No se encontraron registros
                </ng-container>
                <ng-container *ngIf="instanceResponse?.response">
                    <p-divider />
                    <ng-container>
                        <a [href]="instanceResponse?.files[0]"
                           [laLink]="instanceResponse?.files[0]"
                           [uuid]="instanceResponse?.uuid">
                            Evidencia
                        </a>
                    </ng-container>
                </ng-container>
            </div>
        </p-accordionTab>

        <ng-container *ngIf="getInstance('fge_notices'); let instanceResponse">
            <p-accordionTab *ngIf="!!instanceResponse.response">
                <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/logoFGE2022.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Noticias Fiscalia General del Estado</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
                </ng-template>

                <div *ngIf="instanceResponse && instanceResponse.response">
                    <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                        <app-fge-notices-display [records]="instanceResponse?.response.records"></app-fge-notices-display>
                    </div>
                    <ng-container *ngIf="instanceResponse?.response?.total == 0">
                        No se encontraron registros
                    </ng-container>
                </div>

                <ng-container *ngIf="instanceResponse?.response">
                    <p-divider />
                    <ng-container>
                        <a [href]="instanceResponse?.files[0]"
                           [laLink]="instanceResponse?.files[0]"
                           [uuid]="instanceResponse?.uuid">
                            Evidencia
                        </a>
                    </ng-container>
                </ng-container>

            </p-accordionTab>
        </ng-container>




        <p-accordionTab *ngIf="getInstance('listas_personalizadas'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/Peps.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Listas Personalizadas</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">

                    <app-black-list-display [records]="instanceResponse?.response.records"></app-black-list-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
            </div>
        </p-accordionTab>




        <p-accordionTab *ngIf="getInstance('offshore'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/offshore.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Offshore Leaks</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-offshore-display [records]="instanceResponse.response?.records"></app-offshore-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a  *ngFor="let file of instanceResponse.files"
                        [href]="file"
                        [laLink]="file"
                        [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>




        <p-accordionTab *ngIf="getInstance('ministerio_trabajo'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/MinisterioTrabajo.png" shape="square" />
                    <span class="font-bold white-space-nowrap">Ministerio del Trabajo (Vinculación con el sector público)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.functionary ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response.functionary ? 'VINCULADO' : 'NO VINCULADO'" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-ministerio-trabajo-display [records]="instanceResponse.response?.records"></app-ministerio-trabajo-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a  *ngFor="let file of instanceResponse.files"
                        [href]="file"
                        [laLink]="file"
                        [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>




        <p-accordionTab *ngIf="getInstance('sri'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/SRI.jpg" shape="square" />
                    <span class="font-bold white-space-nowrap">SRI (Servicio de Rentas Internas)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.functionary ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response.records.length > 0 ? instanceResponse?.response.records[0].contribuyente.estadoContribuyenteRuc: 'Registro no encontrado' " />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-sri-display [records]="instanceResponse.response?.records"></app-sri-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a  *ngFor="let file of instanceResponse.files"
                        [href]="file"
                        [laLink]="file"
                        [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>




        <p-accordionTab *ngIf="getInstance('contraloria'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/CGE.png" shape="square" />
                    <span class="font-bold white-space-nowrap">CGE (Declaraciones Juradas)</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-contraloria-display [records]="instanceResponse.response?.records"></app-contraloria-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a  *ngFor="let file of instanceResponse.files"
                        [href]="file"
                        [laLink]="file"
                        [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>



        <p-accordionTab *ngIf="getInstance('eu_most_wanted'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/enfast.png" shape="square" />
                    <span class="font-bold white-space-nowrap">EU Most Wanted</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total > 0 ? instanceResponse?.response.records[0].dangerous: instanceResponse?.response.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-eu-most-wanted-display [records]="instanceResponse.response?.records"></app-eu-most-wanted-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a  *ngFor="let file of instanceResponse.files"
                        [href]="file"
                        [laLink]="file"
                        [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>


        <p-accordionTab *ngIf="getInstance('dea'); let instanceResponse">
            <ng-template pTemplate="header">
                <span class="flex align-items-center gap-2 w-full">
                    <p-avatar image="assets/logos/DEA_logo.jpg" shape="square" />
                    <span class="font-bold white-space-nowrap">DEA</span>

                    <p-progressSpinner *ngIf="instanceResponse && !instanceResponse.response"
                                       class="ml-auto mr-2"
                                       styleClass="w-2rem h-2rem">
                    </p-progressSpinner>
                    <p-badge *ngIf="instanceResponse && instanceResponse.response"
                             [severity]="instanceResponse?.response?.total > 0 ? 'warning': 'success'"
                             class="ml-auto mr-2 p-badge-success"
                             [value]="instanceResponse?.response?.total > 0 ? instanceResponse?.response.records[0].teaser_category: instanceResponse?.response.total" />
                </span>
            </ng-template>

            <div *ngIf="instanceResponse && instanceResponse.response">
                <div class="m-0" *ngIf="instanceResponse?.response?.total > 0">
                    <app-dea-display [records]="instanceResponse.response?.records"></app-dea-display>
                </div>
                <ng-container *ngIf="instanceResponse?.response?.total == 0">
                    No se encontraron registros
                </ng-container>
                <p-divider />
                <ng-container>
                    <a  *ngFor="let file of instanceResponse.files"
                        [href]="file"
                        [laLink]="file"
                        [uuid]="instanceResponse?.uuid">
                        Evidencia
                    </a>
                </ng-container>
            </div>
        </p-accordionTab>


    </p-accordion>
</div>
