import {Component, Input} from '@angular/core';
import {Dea} from "../../interfaces/dea/dea";
import {DatePipe, NgIf} from "@angular/common";
import {DividerModule} from "primeng/divider";

@Component({
  selector: 'app-dea-display',
  standalone: true,
    imports: [
        NgIf,
        DatePipe,
        DividerModule
    ],
  templateUrl: './dea-display.component.html',
  styleUrl: './dea-display.component.scss'
})
export class DeaDisplayComponent {
    @Input() records: Dea[];
}
