import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {Dashboard} from "../interfaces/dashboard";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

    constructor(private http: HttpClient) { }

    get(): Observable<Dashboard> {
      return this.http.get<Dashboard>('dashboard');
    }
}
