<p-table [value]="batch_registers_import_employees"
         [columns]="cols"
         [rows]="10"
         [paginator]="true"
         responsiveLayout="scroll">

    <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Detalles de la Importación</h5>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Error</th>
            <th>Linea del Error</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-batchRegisterImportEmployee>
        <tr>
            <td><p-tableCheckbox [value]="batchRegisterImportEmployee"></p-tableCheckbox></td>
            <td>{{batchRegisterImportEmployee.errors}}</td>
            <td>{{batchRegisterImportEmployee.line_error}}</td>
        </tr>
    </ng-template>
</p-table>
