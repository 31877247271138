<p-table [value]="batch_import_employees"
         [columns]="cols"
         [rows]="10"
         [paginator]="true"
         responsiveLayout="scroll">

    <ng-template pTemplate="caption">
        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">Estado de la Importación</h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search"></i>
                <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Search..."  class="w-full sm:w-auto"/>
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th style="width: 3rem">
                <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th>Nombre del Archivo</th>
            <th>Status</th>
            <th>Registros Procesables</th>
            <th>Total de Registros</th>
            <th>Registros con Errores</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-batchImportEmployee>
        <tr>
            <td><p-tableCheckbox [value]="batchImportEmployee"></p-tableCheckbox></td>
            <td>{{batchImportEmployee.original_file_name}}</td>
            <td>{{batchImportEmployee.status}}</td>
            <td>{{batchImportEmployee.processable_records}}</td>
            <td>{{batchImportEmployee.total_records}}</td>
            <td>
                <div *ngIf="batchImportEmployee.total_error_records > 0">
                    <button pButton pRipple icon="pi pi-times" class="p-button-text" [routerLink]="batchImportEmployee.uuid">
                        {{batchImportEmployee.total_error_records}}
                    </button>
                </div>
                <div *ngIf="batchImportEmployee.total_error_records == 0">0</div>
            </td>
        </tr>
    </ng-template>
</p-table>
