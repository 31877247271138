import { Component, OnInit } from '@angular/core';
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { SharedModule } from "primeng/api";
import { ToolbarModule } from "primeng/toolbar";
import { List } from "../../../interfaces/list";
import { ListsService } from "../../../services/lists.service";
import { DialogModule } from "primeng/dialog";
import { InputTextModule } from "primeng/inputtext";
import { CommonModule, NgIf } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { TableModule } from "primeng/table";
import { MessageService } from 'primeng/api';
import { ListboxModule } from "primeng/listbox";
import { DropdownModule } from 'primeng/dropdown';
import { SplitButtonModule } from 'primeng/splitbutton';
import {TagModule} from "primeng/tag";


@Component({
    selector: 'app-lists',
    standalone: true,
    imports: [
        ButtonModule,
        RippleModule,
        SharedModule,
        ToolbarModule,
        DialogModule,
        InputTextModule,
        NgIf,
        CommonModule,
        FormsModule,
        TableModule,
        ListboxModule,
        DropdownModule,
        SplitButtonModule,
        TagModule
    ],
    templateUrl: './lists.component.html',
    styleUrl: './lists.component.scss',
    providers: [MessageService]
})
export class ListsComponent implements OnInit{

    lists: List[];
    list: List;
    selectedLists: List[] = [];

    submitted : boolean = false;
    listDialog : boolean = false;
    deleteListsDialog : boolean = false;
    cols: any[] = [];

    private router: any;

    constructor(private listService: ListsService, private messageService: MessageService) {}

    ngOnInit() {
        this.loadLists();
    }

    loadLists(){
        this.listService.getLists().subscribe(data => {
            this.lists = data;
        });
    }

    openNew(){
        this.list = {
            name: ""
        } as List;
        this.submitted = false;
        this.listDialog = true;
    }

    createList(){
        this.listService.createList(this.list).subscribe(data => {
            this.hideDialog();
            this.loadLists();
        });
    }

    hideDialog() {
        this.listDialog = false;
        this.submitted = false;
    }

    deleteSelectedLists() {
        this.deleteListsDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteListsDialog = false;
        this.lists = this.lists.filter(val => !this.selectedLists.includes(val));
        this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Listas Eliminadas', life: 3000 });

        this.selectedLists.forEach((val) => {
            this.listService.deleteList(val.uuid.toString()).subscribe(()=> {
                this.loadLists();
            });
        });
        this.selectedLists = [];
    }

}
