import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {EuMostWanted} from "../../interfaces/eu-most-wanted/eu-most-wanted";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-eu-most-wanted-display',
    standalone: true,
    imports: [
        SharedModule,
        TableModule,
        NgIf
    ],
    templateUrl: './eu-most-wanted-display.component.html',
    styleUrl: './eu-most-wanted-display.component.scss'
})
export class EuMostWantedDisplayComponent {

    @Input() records: EuMostWanted[];

}
