import {Component, ElementRef, inject, OnInit, ViewChild} from '@angular/core';
import {ButtonModule} from "primeng/button";
import {CalendarModule} from "primeng/calendar";
import {DialogModule} from "primeng/dialog";
import {DropdownModule} from "primeng/dropdown";
import {InputTextModule} from "primeng/inputtext";
import {NgClass, NgIf} from "@angular/common";
import {PaginatorModule} from "primeng/paginator";
import {FormControl, FormGroup, ReactiveFormsModule} from "@angular/forms";
import {RippleModule} from "primeng/ripple";
import {MessageService, SharedModule} from "primeng/api";
import {Table, TableModule} from "primeng/table";
import {ToastModule} from "primeng/toast";
import {ToolbarModule} from "primeng/toolbar";
import {RouterLink} from "@angular/router";
import {NotificationService} from "../../services/notification-service.service";
import {EmployeeInterface} from "../../interfaces/employee";
import * as moment from "moment";
import {EmployeesService} from "../../services/employees.service";
import {catchError} from "rxjs/operators";
import {Observable, of} from "rxjs";
import {TagModule} from "primeng/tag";
import {LoadSearchCountAction} from "../../store/user/search-count/search-count.actions";
import {SearchCountResponse} from "../../interfaces/search-count";
import {Store} from "@ngxs/store";
import {SearchCountState} from "../../store/user/search-count/search-count.state";
import {CheckboxModule} from "primeng/checkbox";
import {MultiSelectModule} from "primeng/multiselect";
import {ClientInterface} from "../../interfaces/client";
import {matchFilters} from "../../commons";

interface Type {
    name: string;
    value: string;
}

@Component({
    selector: 'app-employees',
    standalone: true,
    imports: [
        ButtonModule,
        CalendarModule,
        DialogModule,
        DropdownModule,
        InputTextModule,
        NgIf,
        PaginatorModule,
        ReactiveFormsModule,
        RippleModule,
        SharedModule,
        TableModule,
        ToastModule,
        ToolbarModule,
        RouterLink,
        NgClass,
        TagModule,
        CheckboxModule,
        MultiSelectModule
    ],
    templateUrl: './employees.component.html',
    styleUrl: './employees.component.scss',
    providers: [NotificationService, MessageService]
})
export class EmployeesComponent implements OnInit {

    employees: EmployeeInterface[];
    selectedEmployees: EmployeeInterface[] = [];
    coincidences: boolean;

    nuiTypes: Type[] = [];
    employeeTypes: Type[] = [];
    cols: any[] = [];
    employeeForm: FormGroup;
    importEmployeesForm: FormGroup;
    file: File = undefined;

    submitted : boolean = false;
    employeeDialog : boolean = false;
    deleteEmployeesDialog : boolean = false;
    importEmployeesDialog : boolean = false;

    matchFilters = matchFilters;
    matchFiltersSelected = [];

    searchCount$: Observable<SearchCountResponse> = inject(Store).select(SearchCountState.getState);
    searchCount: SearchCountResponse;

    @ViewChild('file') inputFile: ElementRef;

    constructor(private employeeService: EmployeesService,
                private messageService: MessageService,
                private store: Store,
                private notificationService: NotificationService) { }

    ngOnInit() {
        this.createForm();
        this.loadEmployees();
        this.nuiTypes = [
            { name: 'Cédula', value: 'cedula' },
            { name: 'RUC', value: 'ruc' }
        ];
        this.employeeTypes = [
            { name: 'Individuo', value: 'person' },
            { name: 'Compañía', value: 'company' }
        ];
        this.importEmployeesForm = new FormGroup({
            file:new FormControl(''),
        });
    }

    loadEmployees(){
        if(!this.coincidences) {
            this.matchFiltersSelected = [];
        }

        this.employeeService.getEmployees(this.coincidences, this.matchFiltersSelected).subscribe(employees => {
            this.employees = employees;
        });
    }

    openNew(){
        this.createForm();
        this.submitted = false;
        this.employeeDialog = true;
    }

    editSelectedEmployees(employee:EmployeeInterface) {
        this.employeeForm.patchValue({
            name: employee.name,
            last_name: employee.last_name,
            type_nui: employee.type_nui,
            nui: employee.nui,
            type: employee.type,
            dob: moment(employee.dob, 'YYYY-MM-DD').toDate(),
            uuid: employee.uuid
        });
        this.submitted = false;
        this.employeeDialog = true;
    }

    createForm(){
        this.employeeForm = new FormGroup({
            'name': new FormControl(""),
            'last_name': new FormControl(""),
            'type_nui': new FormControl(""),
            'nui': new FormControl(""),
            'type': new FormControl(""),
            'dob': new FormControl(""),
            'uuid': new FormControl("")
        });
    }

    createImportForm(){
        this.importEmployeesForm = new FormGroup({
            'file': new FormControl('')
        });
    }

    closeModal(){
        this.hideDialog();
        this.importEmployeesDialog = false;
        this.loadEmployees();
    }

    hideDialog() {
        this.employeeDialog = false;
        this.submitted = false;
    }

    createEmployee(){
        if (this.employeeForm.valid){
            this.employeeService.createEmployee(this.employeeForm.value).pipe(
                catchError(error => {
                    this.messageService.add({
                        severity: 'warn',
                        summary: 'Límite de Búsquedas',
                        detail: 'Su cupo contratado ha sido alcanzado.'
                    })
                    return of(null);
                })
            ).subscribe(data => {
                this.hideDialog();
                this.loadEmployees();
                this.loadEmployees();
            });
        }
    }

    updateEmployee() {
        this.submitted = true;
        if (this.employeeForm.valid) {
            this.employeeService.updateEmployee(this.employeeForm.value).subscribe(data =>{
                this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Empleado Actualizado', life: 3000 });
                this.hideDialog();
                this.loadEmployees();
            });
        }
    }

    performAction(){
        if (this.employeeForm.get('uuid').value.length == 0)
        {
            this.createEmployee();
        }
        else {
            this.updateEmployee();
        }
    }

    deleteSelectedEmployees() {
        this.deleteEmployeesDialog = true;
    }

    confirmDeleteSelected() {
        this.deleteEmployeesDialog = false;
        this.selectedEmployees.forEach((val) => {
            this.employeeService.deleteEmployee(val.uuid.toString()).subscribe(()=> {
                this.messageService.add({ severity: 'éxito', summary: 'Exitoso', detail: 'Empleado Eliminados', life: 3000 });
                this.loadEmployees();
            });
        });
        this.selectedEmployees = [];
    }

    searchEmployee(employee: EmployeeInterface, showNotification: boolean = true){
        employee.loading = true;
        if (showNotification) {
            this.employeeService.searchEmployee(employee.uuid).subscribe(()=>{
                this.notificationService.showWarnSearched('El registro se encuentra en búsqueda');
                employee.loading = false;
            });
        }
    }

    searchSelectedEmployees(){
        if (this.selectedEmployees.length === 0) {
            this.messageService.add({ severity: 'warn', summary: 'Advertencia', detail: 'No se han seleccionado empleados.', life: 5000 });
            return;
        }

        const uuids: string[] = this.selectedEmployees.map(employee => employee.uuid);
        this.employeeService.searchEmployee(null, uuids).subscribe( () => {
            this.notificationService.showWarnSearched('Los registros se encuentran en búsqueda');
        });
    }


    importEmployees(){
        this.createImportForm();
        this.submitted = false;
        this.importEmployeesDialog = true;
    }

    selectFile(event: Event) {
        const input = event.target as HTMLInputElement;
        this.file = input.files[0];
    }

    importList()
    {
        if (this.importEmployeesForm.value)
        {
            this.employeeService.importEmployee(
                this.file
            ).pipe(
                catchError(error => {
                    this.notificationService.showWarnIncorrectEmailOrPass('La cantidad de búsquedas contratadas ha sido superada.');
                    return of(null);
                })
            ).subscribe(data => {
                setTimeout(() => {
                    this.store.dispatch(new LoadSearchCountAction());
                }, 2000);
                this.importEmployeesForm.reset();
                this.inputFile.nativeElement.value = '';
                this.file = undefined;
                this.importEmployeesDialog = false;
            });
        }
    }


    onGlobalFilter(table: Table, event: Event) {
        table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
    }



}
