import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {DatePipe, NgIf} from "@angular/common";
import {JoinData} from "../../interfaces/sri/sri";
import {DividerModule} from "primeng/divider";

@Component({
    selector: 'app-sri-display',
    standalone: true,
    imports: [
        SharedModule,
        TableModule,
        NgIf,
        DividerModule,
        DatePipe
    ],
    templateUrl: './sri-display.component.html',
    styleUrl: './sri-display.component.scss'
})
export class SriDisplayComponent {

    @Input() records: JoinData[] = [];

}
