<div>
    <div class="container">

        <div class="row">
            <div class="col-3 cell">
                <p><b>Nombre: </b> {{ records[0].name }}</p>
            </div>
            <div class="col-3 cell">
                <p><b>Delito: </b> {{ records[0].delito }}</p>
            </div>
            <div class="col-3 cell">
                <p><span class="enfasis">{{ records[0].dangerous}}</span></p>
            </div>
            <div class="col-3 cell">
                <p><b>{{ records[0].status }} </b> <span class="enfasis"> {{ records[0].country }}</span></p>
            </div>
        </div>

        <div class="row">
            <div class="col-3 cell">
                <p><b>Sexo: </b> {{ records[0].sexo_ }}</p>
            </div>
            <div class="col-3 cell">
                <p><b>Nacionalidad: </b> {{ records[0].nacionalidad }}</p>
            </div>
            <div class="col-3 cell">
                <p><b>Origen Étnico: </b> {{ records[0].origen_etnico }}</p>
            </div>
            <div class="col-3 cell">
                <p><b>Idiomas Hablados: </b> {{ records[0].idiomas_hablados}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-3 cell">
                <p><b>Publicado: </b> {{ records[0].publicado }}</p>
            </div>
            <div class="col-3 cell">
                <p><b>Fecha de Nacimiento: </b> {{ records[0].fecha_de_nacimiento }}</p>
            </div>
            <div class="col-3 cell">
                <p><b>Altura Aproximada: </b> {{ records[0].altura_aproximada}}</p>
            </div>
            <div class="col-3 cell">
                <p><b>Estado del Caso: </b> {{ records[0].estado_del_caso }}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-12 cell">
                <b>Detalles: </b> <div [innerHTML]="records[0].details"></div>
            </div>
        </div>

    </div>
</div>
