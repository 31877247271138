export const matchFilters = [
    {'id':'interpol', 'name': 'Interpol'},
    {'id':'cj_causas', 'name': 'Funcion Judicial Causas'},
    {'id':'cj_condenas', 'name': 'Funcion Judicial Penas'},
    {'id':'nnuu_entity', 'name': 'Naciones Unidas Entidades'},
    {'id':'nnuu_individual', 'name': 'Naciones Unidas Individuos'},
    {'id': 'fge_notices', 'name':'Fiscalía'},
    {'id': 'cne_pep', 'name':'PEP voto popular'},
    {'id':'peps', 'name':'PEP cago público'},
    {'id':'ofac', 'name':'Ofac'},
    {'id':'iess_certificado_afiliacion', 'name':'Certificado Afiliacion IESS'},
    {'id':'listas_personalizadas', 'name':'Listas Personalizadas'},
    {'id':'empresas_fantasma_ec', 'name':'Empresas Fantasma'},
    {'id':'mas_buscados_ec', 'name':'Más Buscados'},
    {'id':'offshore', 'name':'Offshore Leaks'},
];
