import {Component, OnInit} from '@angular/core';
import {BatchImportClientsService} from "../../../services/batch-import-clients.service";
import {BatchImportClientInterface} from "../../../interfaces/batch-import-client";
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {RouterLink} from "@angular/router";
import {RippleModule} from "primeng/ripple";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-batch-import',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        SharedModule,
        TableModule,
        RouterLink,
        RippleModule,
        NgIf
    ],
    templateUrl: './batch-import.component.html',
    styleUrl: './batch-import.component.scss'
})
export class BatchImportClientsComponent implements OnInit{

    batch_import_clients: BatchImportClientInterface[];
    cols: any[] = [];

    constructor(private batchImportService: BatchImportClientsService) {
    }

    ngOnInit() {
        this.loadBatchImport();
    }

    loadBatchImport(){
        this.batchImportService.getBatchImportClients().subscribe(batch_import_clients => {
            this.batch_import_clients = batch_import_clients;
        });
    }

}
