import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BatchRegistersImportSuppliersInterface} from "../interfaces/batch-registers-import-suppliers";

@Injectable({
  providedIn: 'root'
})
export class BatchRegistersImportSuppliersService {

    constructor(private http: HttpClient) {}

    public getBatchRegistersImportSuppliers = (uuid:string): Observable<BatchRegistersImportSuppliersInterface[]> => {
        return this.http.get<BatchRegistersImportSuppliersInterface[]>(`batch_import_suppliers/${uuid}`);
    }

}
