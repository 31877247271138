import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {DatePipe, NgIf} from "@angular/common";
import {MultiSelectModule} from "primeng/multiselect";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {FormsModule} from "@angular/forms";
import {OffshoreInterface} from "../../interfaces/offshore/offshore";

@Component({
    selector: 'app-offshore-display',
    standalone: true,
    imports: [
        DatePipe,
        MultiSelectModule,
        NgIf,
        SharedModule,
        TableModule,
        FormsModule
    ],
    templateUrl: './offshore-display.component.html',
    styleUrl: './offshore-display.component.scss'
})
export class OffshoreDisplayComponent implements  OnChanges{

    @Input() records: OffshoreInterface[] = [];
    filteredEntities: OffshoreInterface[] = [];
    paradise: string[] = [];

    ngOnChanges(changes: SimpleChanges) {

        if(!this.records) {
            return;
        }

        let paradise = {};
        this.records.forEach((offshore) => {
            paradise[offshore.entity]=offshore.entity;
        });
        this.paradise = Object.values(paradise);
        this.filteredEntities = this.records;
    }

}
