import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {NgIf} from "@angular/common";
import {SriResponse} from "../../interfaces/sri/sri";

@Component({
    selector: 'app-sri-display',
    standalone: true,
    imports: [
        SharedModule,
        TableModule,
        NgIf
    ],
    templateUrl: './sri-display.component.html',
    styleUrl: './sri-display.component.scss'
})
export class SriDisplayComponent {
    @Input() records: SriResponse[] = [];
}
