import {Component, Input} from '@angular/core';
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ContraloriaRecord} from "../../interfaces/contraloria/contraloria";

@Component({
    selector: 'app-contraloria-display',
    standalone: true,
    imports: [
        SharedModule,
        TableModule
    ],
    templateUrl: './contraloria-display.component.html',
    styleUrl: './contraloria-display.component.scss'
})
export class ContraloriaDisplayComponent {

    @Input() records: ContraloriaRecord[];

}
