import { Component, OnInit } from '@angular/core';
import {ButtonModule} from "primeng/button";
import {InputTextModule} from "primeng/inputtext";
import {SharedModule} from "primeng/api";
import {TableModule} from "primeng/table";
import {ActivatedRoute} from "@angular/router";
import {BatchRegistersImportEmployeesInterface} from "../../../../interfaces/batch-registers-import-employees";
import {BatchRegistersImportEmployeesService} from "../../../../services/batch-registers-import-employees.service";

@Component({
    selector: 'app-batch-registers-import',
    standalone: true,
    imports: [
        ButtonModule,
        InputTextModule,
        SharedModule,
        TableModule
    ],
    templateUrl: './batch-registers-import.component.html',
    styleUrl: './batch-registers-import.component.scss'
})
export class BatchRegistersImportEmployeesComponent implements OnInit{

    batch_registers_import_employees: BatchRegistersImportEmployeesInterface[];
    cols: any[] = [];
    uuid: string | null = null;

    constructor(private batchRegistersImportService: BatchRegistersImportEmployeesService,
                private route:ActivatedRoute) {
    }

    ngOnInit() {
        this.route.params.subscribe(params => {
            this.uuid = params['uuid'];
            this.loadBatchRegistersImport();
        });
    }

    loadBatchRegistersImport(){
        this.batchRegistersImportService.getBatchRegistersImportEmployees(this.uuid).subscribe(batch_registers_import_employees => {
            this.batch_registers_import_employees = batch_registers_import_employees;
        });
    }

}
