<div class="grid" *ngIf="dashboardData">
    <div class="col-12 lg:col-6 xl:col-3">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Clientes</span>
                    <div class="text-900 font-medium text-xl">Total: {{ dashboardData.clients.total_count }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-blue-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-users text-blue-500 text-xl"></i>
                </div>
            </div>
            <span class="text-green-500 font-medium">
                <a [routerLink]="['clients']" [queryParams]="{ match: true }">
                    {{ dashboardData.clients.match_count }}
                </a>
            </span>
            <span class="text-500">con coincidencias.</span>
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Proveedores</span>
                    <div class="text-900 font-medium text-xl">Total: {{ dashboardData.suppliers.total_count }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-orange-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-users text-orange-500 text-xl"></i>
                </div>
            </div>
            <span class="text-green-500 font-medium">
                <a [routerLink]="['suppliers']" [queryParams]="{ match: true }">
                    {{ dashboardData.suppliers.match_count }}
                </a>
            </span>
            <span class="text-500">con coincidencias.</span>
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Empleados</span>
                    <div class="text-900 font-medium text-xl"> Total: {{ dashboardData.employees.total_count }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-users text-cyan-500 text-xl"></i>
                </div>
            </div>
            <span class="text-green-500 font-medium">
                 <a [routerLink]="['employees']" [queryParams]="{ match: true }">
                    {{ dashboardData.employees.match_count }}
                </a>
            </span>
            <span class="text-500">con coincidencias. </span>
        </div>
    </div>
    <div class="col-12 lg:col-6 xl:col-3">
        <div class="card mb-0">
            <div class="flex justify-content-between mb-3">
                <div>
                    <span class="block text-500 font-medium mb-3">Búsquedas</span>
                    <div class="text-900 font-medium text-xl"> Total: {{ dashboardData.search.total_count }}</div>
                </div>
                <div class="flex align-items-center justify-content-center bg-purple-100 border-round" [ngStyle]="{width: '2.5rem', height: '2.5rem'}">
                    <i class="pi pi-search text-purple-500 text-xl"></i>
                </div>
            </div>
            <span class="text-green-500 font-medium">{{ dashboardData.search.user_count }} </span>
            <span class="text-500">realizadas por el usuario actual.</span>
        </div>
    </div>



    <div class="col-12 xl:col-12">
        <div class="card">
            <h5>Coincidencias</h5>
            <p-table [value]="dashboardData.warnings" [paginator]="true" [rows]="5" responsiveLayout="scroll">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Nombre o Razón Social</th>
                        <th>Identificación</th>
                        <th>Coincidencia</th>
                        <th>Tipo</th>
                        <th>Revisar</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-search>
                    <tr>
                        <td style="width: 35%; min-width: 5rem;">
                            {{ search.query.last_name }} {{ search.query.name }}
                        </td>
                        <td style="width: 15%; min-width: 7rem;">{{ search.query.id }} </td>
                        <td style="width: 35%; min-width: 8rem;">
                            <span>{{ search.warning_details.join(', ') }}</span>
                        </td>
                        <td>
                            <span *ngIf="search.client_id">Cliente</span>
                            <span *ngIf="search.supplier_id">Proveedor</span>
                            <span *ngIf="search.employee_id">Empleado</span>
                        </td>
                        <td style="width: 15%;">
                            <button [routerLink]="['search', search.uuid]" pButton pRipple type="button" icon="pi pi-search" class="p-button p-component p-button-text p-button-icon-only"></button>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>
