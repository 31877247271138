<div class="container">
    <div class="row">

        <div class="col-2 cell">
            <a [href]="records[0].url" target="_blank">
                <img src="{{ records[0].urlImg }}" width="200">
            </a>
        </div>
        <div class="col-10 cell">
            <p><b>Status: </b> <span class="enfasis"> {{ records[0]?.teaser_category }} </span></p>
            <p><b>Nombre: </b> <a [href]="records[0].url" target="_blank"> {{ records[0]?.name }} </a></p>
            <p><b>Descripción: </b> {{ records[0]?.teaser_text }} </p>
        </div>

    </div>
</div>
